import { createContext, useContext, useEffect, useState } from "react";


export const NavModalContext = createContext([false, null]);

export function useNavModalContext (){
	return useContext(NavModalContext);
}

export function NavModalContextProvider ({children}) {

	const [isModalOpen, setModalOpen] = useState(false);

	const slideNavModalIn = () => {
		let navmodalElement = document.getElementById("navmodal");
		navmodalElement.style.animation = "slideIn 0.5s ease-out forwards";
	}

	const slideNavModalOut = () => {
		let navmodalElement = document.getElementById("navmodal") || {style: {}};
		navmodalElement.style.animation = "slideOut 0.5s ease-out forwards";
	}

	useEffect(() => {
		if (isModalOpen){
			slideNavModalIn();
		} else {
			slideNavModalOut();
		}

	}, [isModalOpen])

	return (
		<NavModalContext.Provider value={[isModalOpen, setModalOpen]}>
			{children}
		</NavModalContext.Provider>
	)
}