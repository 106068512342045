import { useEffect, useState } from "react";
import { useNetworkState } from "react-use";
import { ServerDiscoveryInfoCard } from "./ServerDiscoveryInfoCard";

export const ServerDiscoverer = (props) => {

	const [serverDiscoveryState, setServerDiscoveryState] = useState("off");

	const [foundServers, setFoundServers] = useState([]);

	const networkState = useNetworkState();

	const startServerDiscovery = () => {
		setFoundServers([]);
		setServerDiscoveryState("searching");

	}


	useEffect(() => {
		console.log("Server discovery state: " + serverDiscoveryState);

		if (serverDiscoveryState === "searching") {
			findAndPingLocalDevices();
		}

	}, [serverDiscoveryState]);

	const findAndPingLocalDevices = async () => {





		let handshakeRoute = "/api/server/clientHandshake";
		let ipLowEnd = 0;
		let ipMaxEnd = 255;
		let localIp = "192.168.0.";
		let targetPort = 7474;

		try {
			fetch("http://localhost:" + targetPort + handshakeRoute)
			.then(response => response.json())
			.then(processedResponse => {
				console.log("Localhost ping completed.");
				setFoundServers(previousState => [
					...previousState,
					{
						name: processedResponse.name,
						address: "http://localhost",
						port: targetPort
					}
				]);
			})
			.catch(() => null);
		} catch (error) {
			console.log("Server Discovery did not find a local on-device server...");
		}

		// HTTP
		for (let index = ipLowEnd; index < ipMaxEnd; index++) {
			let iterationTarget = "http://" + localIp + index + ":" + targetPort + handshakeRoute;
			console.log("Pinging: " + iterationTarget);
			
			try {
				fetch(iterationTarget)
				.then(response => response.json())
				.then(processedResponse => {setFoundServers(previousState => [
						...previousState,
						{
							name: processedResponse.name,
							address: "http://" + localIp + index,
							port: targetPort
						}
					]);
				})
				.catch(() => null);
			} catch (error) {
				console.log("Server Discovery did not find a WLAN-based server for address: " + iterationTarget);
			}
		}

		// HTTPS
		for (let index = ipLowEnd; index < ipMaxEnd; index++) {
			let iterationTarget = "https://" + localIp + index + ":" + targetPort + handshakeRoute;
			console.log("Pinging: " + iterationTarget);
			
			try {
				fetch(iterationTarget)
				.then(response => response.json())
				.then(processedResponse => {setFoundServers(previousState => [
						...previousState,
						{
							name: processedResponse.name,
							address: "https://" + localIp + index,
							port: targetPort
						}
					]);
				})
				.catch(() => null);
			} catch (error) {
				console.log("Server Discovery did not find a WLAN-based server for address: " + iterationTarget);
			}
		}
		
		// Wait 5 seconds before letting users search again.
		// Reduces spammed requests on a network.
		setTimeout(() => {
			setServerDiscoveryState("off");
		}, 10000);
	}


	return(
		<section id="serverDiscoverer">
			
			<button 
				disabled={serverDiscoveryState === "searching" ? true : false}
				onClick={startServerDiscovery}
			>
				
				{serverDiscoveryState === "searching" ? "Finding Local Servers..." : "Find Local Servers"}

			</button>

			{serverDiscoveryState && <h5>Reachable Servers</h5>}
			<section>
				{foundServers && foundServers.map((server) => {
					return <ServerDiscoveryInfoCard key={server.address} propKey={server.address} server={server} />
				})}
			</section>
			{networkState.type}
			{networkState.type === "wifi" && "Connected via wifi."}
		</section>
	)
}