import { useNavModalContext } from "../contexts/NavModalContext"
import { NavlinksList } from "./NavlinksList"
import CloseIcon from '@mui/icons-material/Close';
import { ServerStatusWidget } from "./ServerStatusWidget";
import '../styles/NavModal.css';

export const NavModal = (props) => {

	// eslint-disable-next-line no-unused-vars
	const [isNavbarModalOpen, setNavbarModalOpen] = useNavModalContext();

	return (
		<span id="navmodal">
			<CloseIcon onClick={() => setNavbarModalOpen(false)} />
			<NavlinksList />
			<ServerStatusWidget />
		</span>
	)
	
}