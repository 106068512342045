import { Outlet, useLocation, useNavigate } from 'react-router';
import LandingPage from './pages/LandingPage';
import { useConnectedServerContext } from './contexts/ConnectedServerContext';
import { useEffect } from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NavModal } from './components/NavModal';

function App() {

  let location = useLocation();
  const navigate = useNavigate();

  const serverConnectionData = useConnectedServerContext();

  useEffect(() => {
    // trigger re-render on data update
	  if (!serverConnectionData.port || !serverConnectionData.address || !serverConnectionData.connected){
		  navigate("/servers/select")
	  } else if (serverConnectionData.adminCount === 0) {
		  navigate("/users/register");
	  }
  }, [navigate, serverConnectionData])



  if (location.pathname === "/") {
    return <>
      <div className='row'>
        <Header />
      </div>
      <main className='App row'>
        <NavModal />
        <LandingPage />
      </main>
      <div className='row'>
        <Footer />
      </div>
    </>
  } else {
    return <>
      <div className='row'>
        <Header />
      </div>
      <main className='App row'>
        <NavModal />
        <Outlet />
      </main>
      <div className='row'>
        <Footer />
      </div>
    </>
  }


}

export default App;
