import { NavLink } from "react-router-dom";
import { useNavModalContext } from "../contexts/NavModalContext";


export function NavlinksList (props) {

	// eslint-disable-next-line no-unused-vars
	const [isNavbarModalOpen, setNavbarModalOpen] = useNavModalContext();

	const closeNavmodal = () => {
		setNavbarModalOpen(false);
	}

	return <nav className={props.className}>
		<NavLink to="/" onClick={closeNavmodal} >
			Home
		</NavLink>
		<NavLink to="/users/register" onClick={closeNavmodal} >
			Register
		</NavLink>
		<NavLink to="/users/login" onClick={closeNavmodal} >
			Login
		</NavLink>
	</nav>
}