import { useNavigate } from "react-router";
import { UserForm } from "../components/UserForm";
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from "react";
import { useConnectedServerContext } from "../contexts/ConnectedServerContext";


export default function UserSelectionPage(props){

	const authContextData = useAuth();
	const navigate = useNavigate();
	const serverData = useConnectedServerContext();


	useEffect(() => {
		if (authContextData._id){
			navigate("/landing");
		} 
	}, [authContextData, navigate])

	return (
		<div>
			{
				props.action === "register" ? 
				<h1>Create a new user</h1> 
				: 
				<h1>Log in to your account</h1>
			}

			{
				serverData.adminCount === 0 ?
				<>
					<p>Since no other admins exist in this server yet, we'll assume you're the first and are setting the server up for the first time.</p>
					<p>The user that you create now will be the first admin in the server, and you will be redirected to the server setup process when you submit this page's form.</p>
				</>
				:
				null
			}
			
			<UserForm action={props.action} />
		</div>
	)
	
}