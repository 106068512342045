

export const Footer = (props) => {

	return (
		<footer>
			<a href="https://github.com/BigfootDS/Sourcepool">
				Source Code
			</a>
			<a href="https://github.com/sponsors/BigfootDS">
				Donate to Sourcepool development
			</a>
		</footer>
	)
}