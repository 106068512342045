import { Link } from "react-router-dom";
import { useConnectedServerContext } from "../contexts/ConnectedServerContext"
import { NavlinksList } from "./NavlinksList";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavModalContext } from "../contexts/NavModalContext";

export const Header = (props) => {

	const serverData = useConnectedServerContext();

	const [isNavbarModalOpen, setModalOpen] = useNavModalContext();

	const handleToggleNavbarModal = () => {
		console.log("Toggling navbar modal now...");
		// console.log(setModalOpen);
		setModalOpen(!isNavbarModalOpen);
	}

	return <header>
		<span className="headerMainIcon">
			<Link to="/" >
				<h1>{serverData.name}</h1>
			</Link>
			<Link to="https://github.com/BigfootDS/Sourcepool">
				<p>A Sourcepool Server</p>
			</Link>
		</span>

		<span className="headerNav">
			<span onClick={handleToggleNavbarModal}>
				<MenuIcon className="headerNavIcon" />
			</span>
			
			<NavlinksList className={"headerNavlinks"} />
		</span>
	</header>
}