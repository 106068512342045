import { useNavigate } from "react-router-dom";
import { useConnectedServerContext } from "../contexts/ConnectedServerContext"
import { useEffect } from "react";


export default function LandingPage (props){

	const serverData = useConnectedServerContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!serverData.connected) {
			navigate("/servers/select");
		}
	}, [navigate, serverData]);
	
	return(
		<div id="landingPage">
			<h1>Welcome!</h1>
		</div>
	)
}