import { useConnectedServerContext, useConnectedServerDispatchContext } from "../contexts/ConnectedServerContext"
import { useNavModalContext } from "../contexts/NavModalContext";


export const ServerStatusWidget = (props) => {

	const serverConnectionData = useConnectedServerContext();
	const updateServerConnectionData = useConnectedServerDispatchContext();
	// eslint-disable-next-line no-unused-vars
	const [isNavbarModalOpen, setNavbarModalOpen] = useNavModalContext();

	return (
		<section className="serverStatusWidget">
			{/* {serverConnectionData.name && <h1>{serverConnectionData.name}</h1>} */}
			{serverConnectionData.connected && <p>Server Connected ✅</p>}
			{serverConnectionData.connected && 
			<button onClick={() => {
				updateServerConnectionData({type:'disconnect'});
				setNavbarModalOpen(false);
				}}>
				Disconnect
			</button>
			}
		</section>
	)
}