import { useConnectedServerDispatchContext } from "../contexts/ConnectedServerContext"


export const ServerDiscoveryInfoCard = (props) => {

	const serverConnectionDispatch = useConnectedServerDispatchContext();

	const connectToServer = () => {
		serverConnectionDispatch({
			type: "set",
			data: {
				address: props.server.address,
				port: props.server.port
			}
		})
	}

	return (
	<section className="serverDiscoveryInfoCard" key={props.propKey}>
		<h5>
			{props.server.name}
		</h5>
		<h6>
			{props.server.address}:{props.server.port}
		</h6>
		<button onClick={connectToServer}>
			Connect
		</button>
	</section>
	);
}